export const queryParams = [
  "query_key",
  "query_type",
  "description",
  "shortcode",
  "author_name",
  "publish_status",
  "content_type",
  "author",
  "classType",
  "teacher_name",
  "first_save",
];
export const paramsWithDefault = [
  "sid",
  "search_key",
  "back",
  "searchOutcome",
  "searchMedia",
  "id",
  "is_unpub",
  "status",
  "program_group",
  "name",
  "author_id",
  "outcome_id",
  "before",
  "teacher_id",
  "class_id",
  "lesson_plan_id",
  "student_id",
  "path",
  "school_id",
  "subject_id",
];
