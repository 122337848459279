enum PermissionType {
  create_an_organization_account_1 = "create_an_organization_account_1",
  delete_an_organization_account_2 = "delete_an_organization_account_2",
  reset_an_organization_account_3 = "reset_an_organization_account_3",
  setup_an_organizations_academic_profile_4 = "setup_an_organizations_academic_profile_4",
  edit_an_organization_details_5 = "edit_an_organization_details_5",
  update_default_thumbnails_6 = "update_default_thumbnails_6",
  upgrade_organization_publishing_permissions_7 = "upgrade_organization_publishing_permissions_7",
  approve_and_reject_pending_content_8 = "approve_and_reject_pending_content_8",
  create_program_category_9 = "create_program_category_9",
  change_an_organizations_visibility_settings_10 = "change_an_organizations_visibility_settings_10",
  view_organization_bill_details_11 = "view_organization_bill_details_11",
  block_org_users_access_12 = "block_org_users_access_12",
  create_pricing_plan_13 = "create_pricing_plan_13",
  logos_1000 = "logos_1000",
  my_account_1010 = "my_account_1010",
  my_settings_1020 = "my_settings_1020",
  personalizations_1030 = "personalizations_1030",
  my_learners_page_1040 = "my_learners_page_1040",
  my_learners_dashboards_1041 = "my_learners_dashboards_1041",
  my_learners_schedule_1042 = "my_learners_schedule_1042",
  my_learners_study_plan_1043 = "my_learners_study_plan_1043",
  my_learners_notifications_1044 = "my_learners_notifications_1044",
  my_learners_make_a_payment_1045 = "my_learners_make_a_payment_1045",
  my_learners_coupon_management_1046 = "my_learners_coupon_management_1046",
  my_learners_view_promotions_1047 = "my_learners_view_promotions_1047",
  my_learners_class_recordings_1048 = "my_learners_class_recordings_1048",
  my_learners_attendance_report_1049 = "my_learners_attendance_report_1049",
  live_100 = "live_100",
  go_live_101 = "go_live_101",
  view_organization_live_recordings_110 = "view_organization_live_recordings_110",
  manage_live_classroom_recording_120 = "manage_live_classroom_recording_120",
  override_live_classroom_recording_130 = "override_live_classroom_recording_130",
  delete_live_classroom_recordings_140 = "delete_live_classroom_recordings_140",
  live_default_interface_170 = "live_default_interface_170",
  auto_interface_based_on_age_range_171 = "auto_interface_based_on_age_range_171",
  record_live_classes_172 = "record_live_classes_172",
  record_class_classes_173 = "record_class_classes_173",
  collaboration_show_web_cam_dynamic_174 = "collaboration_show_web_cam_dynamic_174",
  collaboration_show_web_cam_focus_175 = "collaboration_show_web_cam_focus_175",
  collaboration_teacher_present_176 = "collaboration_teacher_present_176",
  collaboration_observe_mode_177 = "collaboration_observe_mode_177",
  collaboration_screenshare_mode_178 = "collaboration_screenshare_mode_178",
  participants_tab_179 = "participants_tab_179",
  lesson_plan_tab_180 = "lesson_plan_tab_180",
  teaches_desk_tab_181 = "teaches_desk_tab_181",
  settings_tab_182 = "settings_tab_182",
  view_lesson_attachments_183 = "view_lesson_attachments_183",
  view_starred_content_184 = "view_starred_content_184",
  see_recommended_content_based_on_age_range_185 = "see_recommended_content_based_on_age_range_185",
  attend_live_class_as_a_teacher_186 = "attend_live_class_as_a_teacher_186",
  attend_live_class_as_a_student_187 = "attend_live_class_as_a_student_187",
  library_200 = "library_200",
  create_content_page_201 = "create_content_page_201",
  unpublished_content_page_202 = "unpublished_content_page_202",
  pending_content_page_203 = "pending_content_page_203",
  published_content_page_204 = "published_content_page_204",
  archived_content_page_205 = "archived_content_page_205",
  view_asset_db_300 = "view_asset_db_300",
  view_my_unpublished_content_210 = "view_my_unpublished_content_210",
  view_org_unpublished_content_211 = "view_org_unpublished_content_211",
  view_my_pending_212 = "view_my_pending_212",
  view_org_pending_213 = "view_org_pending_213",
  view_my_published_214 = "view_my_published_214",
  view_org_published_215 = "view_org_published_215",
  view_my_archived_216 = "view_my_archived_216",
  view_org_archived_217 = "view_org_archived_217",
  view_my_school_published_218 = "view_my_school_published_218",
  view_my_school_pending_225 = "view_my_school_pending_225",
  view_my_school_archived_226 = "view_my_school_archived_226",
  view_all_schools_published_227 = "view_all_schools_published_227",
  view_all_schools_pending_228 = "view_all_schools_pending_228",
  view_all_schools_archived_229 = "view_all_schools_archived_229",
  view_folders_255 = "view_folders_255",
  create_lesson_material_220 = "create_lesson_material_220",
  create_lesson_plan_221 = "create_lesson_plan_221",
  copy_content_222 = "copy_content_222",
  create_my_schools_content_223 = "create_my_schools_content_223",
  create_all_schools_content_224 = "create_all_schools_content_224",
  create_folders_256 = "create_folders_256",
  edit_my_unpublished_content_230 = "edit_my_unpublished_content_230",
  edit_org_unpublished_content_231 = "edit_org_unpublished_content_231",
  edit_my_pending_content_232 = "edit_my_pending_content_232",
  edit_org_pending_content_233 = "edit_org_pending_content_233",
  edit_my_published_content_234 = "edit_my_published_content_234",
  edit_org_published_content_235 = "edit_org_published_content_235",
  edit_lesson_material_metadata_and_content_236 = "edit_lesson_material_metadata_and_content_236",
  edit_lesson_plan_metadata_237 = "edit_lesson_plan_metadata_237",
  edit_lesson_plan_content_238 = "edit_lesson_plan_content_238",
  download_lesson_plan_239 = "download_lesson_plan_239",
  edit_my_schools_published_247 = "edit_my_schools_published_247",
  edit_my_schools_pending_248 = "edit_my_schools_pending_248",
  edit_all_schools_published_249 = "edit_all_schools_published_249",
  edit_all_schools_pending_250 = "edit_all_schools_pending_250",
  edit_folders_257 = "edit_folders_257",
  delete_my_unpublished_content_240 = "delete_my_unpublished_content_240",
  delete_my_schools_pending_241 = "delete_my_schools_pending_241",
  remove_my_schools_published_242 = "remove_my_schools_published_242",
  delete_my_schools_archived_243 = "delete_my_schools_archived_243",
  delete_all_schools_pending_244 = "delete_all_schools_pending_244",
  remove_all_schools_published_245 = "remove_all_schools_published_245",
  delete_all_schools_archived_246 = "delete_all_schools_archived_246",
  remove_org_published_content_254 = "remove_org_published_content_254",
  delete_my_pending_251 = "delete_my_pending_251",
  delete_org_pending_content_252 = "delete_org_pending_content_252",
  delete_org_archived_content_253 = "delete_org_archived_content_253",
  delete_folders_258 = "delete_folders_258",
  library_settings_270 = "library_settings_270",
  approve_pending_content_271 = "approve_pending_content_271",
  reject_pending_content_272 = "reject_pending_content_272",
  archive_published_content_273 = "archive_published_content_273",
  republish_archived_content_274 = "republish_archived_content_274",
  delete_archived_content_275 = "delete_archived_content_275",
  full_content_management_294 = "full_content_management_294",
  details_upload_thumbnail_276 = "details_upload_thumbnail_276",
  details_manually_add_program_277 = "details_manually_add_program_277",
  details_manually_add_developmental_skill_278 = "details_manually_add_developmental_skill_278",
  details_manually_add_skills_category_279 = "details_manually_add_skills_category_279",
  details_manually_add_suitable_age_280 = "details_manually_add_suitable_age_280",
  details_manually_add_grade_281 = "details_manually_add_grade_281",
  share_content_282 = "share_content_282",
  favorite_content_283 = "favorite_content_283",
  associate_learning_outcomes_284 = "associate_learning_outcomes_284",
  publish_featured_content_with_lo_285 = "publish_featured_content_with_lo_285",
  publish_featured_content_no_lo_286 = "publish_featured_content_no_lo_286",
  publish_free_content_with_lo_287 = "publish_free_content_with_lo_287",
  publish_free_content_no_lo_288 = "publish_free_content_no_lo_288",
  create_folder_289 = "create_folder_289",
  view_folder_290 = "view_folder_290",
  edit_folder_291 = "edit_folder_291",
  delete_folder_292 = "delete_folder_292",
  bulk_visibility_settings_293 = "bulk_visibility_settings_293",
  asset_db_300 = "asset_db_300",
  create_asset_page_301 = "create_asset_page_301",
  view_asset_310 = "view_asset_310",
  view_live_recordings_311 = "view_live_recordings_311",
  create_asset_320 = "create_asset_320",
  upload_asset_321 = "upload_asset_321",
  edit_asset_330 = "edit_asset_330",
  download_asset_331 = "download_asset_331",
  delete_asset_340 = "delete_asset_340",
  asset_db_settings_380 = "asset_db_settings_380",
  assessments_400 = "assessments_400",
  create_learning_outcome_page_401 = "create_learning_outcome_page_401",
  unpublished_page_402 = "unpublished_page_402",
  pending_page_403 = "pending_page_403",
  learning_outcome_page_404 = "learning_outcome_page_404",
  milestones_page_405 = "milestones_page_405",
  assessments_page_406 = "assessments_page_406",
  standards_page_407 = "standards_page_407",
  view_my_unpublished_learning_outcome_410 = "view_my_unpublished_learning_outcome_410",
  view_org_unpublished_learning_outcome_411 = "view_org_unpublished_learning_outcome_411",
  view_my_pending_learning_outcome_412 = "view_my_pending_learning_outcome_412",
  view_org_pending_learning_outcome_413 = "view_org_pending_learning_outcome_413",
  view_completed_assessments_414 = "view_completed_assessments_414",
  view_in_progress_assessments_415 = "view_in_progress_assessments_415",
  view_published_learning_outcome_416 = "view_published_learning_outcome_416",
  view_unpublished_milestone_417 = "view_unpublished_milestone_417",
  view_my_unpublished_milestone_428 = "view_my_unpublished_milestone_428",
  view_published_milestone_418 = "view_published_milestone_418",
  view_my_pending_milestone_429 = "view_my_pending_milestone_429",
  view_pending_milestone_486 = "view_pending_milestone_486",
  view_unpublished_standard_419 = "view_unpublished_standard_419",
  view_published_standard_420 = "view_published_standard_420",
  view_org_completed_assessments_424 = "view_org_completed_assessments_424",
  view_org_in_progress_assessments_425 = "view_org_in_progress_assessments_425",
  view_school_completed_assessments_426 = "view_school_completed_assessments_426",
  view_school_in_progress_assessments_427 = "view_school_in_progress_assessments_427",
  create_learning_outcome_421 = "create_learning_outcome_421",
  create_milestone_422 = "create_milestone_422",
  create_standard_423 = "create_standard_423",
  edit_my_unpublished_learning_outcome_430 = "edit_my_unpublished_learning_outcome_430",
  edit_org_unpublished_learning_outcome_431 = "edit_org_unpublished_learning_outcome_431",
  remove_content_learning_outcomes_cart_432 = "remove_content_learning_outcomes_cart_432",
  add_content_learning_outcomes_433 = "add_content_learning_outcomes_433",
  edit_my_pending_learning_outcome_434 = "edit_my_pending_learning_outcome_434",
  edit_org_pending_learning_outcome_435 = "edit_org_pending_learning_outcome_435",
  edit_published_learning_outcome_436 = "edit_published_learning_outcome_436",
  edit_attendance_for_completed_assessment_437 = "edit_attendance_for_completed_assessment_437",
  edit_attendance_for_in_progress_assessment_438 = "edit_attendance_for_in_progress_assessment_438",
  edit_in_progress_assessment_439 = "edit_in_progress_assessment_439",
  edit_unpublished_milestone_440 = "edit_unpublished_milestone_440",
  edit_my_unpublished_milestone_487 = "edit_my_unpublished_milestone_487",
  delete_org_pending_milestone_489 = "delete_org_pending_milestone_489",
  edit_published_milestone_441 = "edit_published_milestone_441",
  edit_unpublished_standard_442 = "edit_unpublished_standard_442",
  edit_published_standard_443 = "edit_published_standard_443",
  delete_my_unpublished_learning_outcome_444 = "delete_my_unpublished_learning_outcome_444",
  delete_org_unpublished_learning_outcome_445 = "delete_org_unpublished_learning_outcome_445",
  delete_my_pending_learning_outcome_446 = "delete_my_pending_learning_outcome_446",
  delete_org_pending_learning_outcome_447 = "delete_org_pending_learning_outcome_447",
  delete_my_pending_milestone_490 = "delete_my_pending_milestone_490",
  delete_published_learning_outcome_448 = "delete_published_learning_outcome_448",
  delete_unpublished_milestone_449 = "delete_unpublished_milestone_449",
  delete_my_unpublished_milestone_488 = "delete_my_unpublished_milestone_488",
  delete_published_milestone_450 = "delete_published_milestone_450",
  delete_unpublished_standard_451 = "delete_unpublished_standard_451",
  delete_published_standard_452 = "delete_published_standard_452",
  delete_in_progress_assessments_453 = "delete_in_progress_assessments_453",
  assessment_settings_480 = "assessment_settings_480",
  approve_pending_learning_outcome_481 = "approve_pending_learning_outcome_481",
  reject_pending_learning_outcome_482 = "reject_pending_learning_outcome_482",
  approve_pending_milestone_491 = "approve_pending_milestone_491",
  reject_pending_milestone_492 = "reject_pending_milestone_492",
  upload_learning_outcomes_483 = "upload_learning_outcomes_483",
  download_learning_outcomes_484 = "download_learning_outcomes_484",
  add_learning_outcome_to_content_485 = "add_learning_outcome_to_content_485",
  schedule_500 = "schedule_500",
  create_schedule_page_501 = "create_schedule_page_501",
  view_my_calendar_510 = "view_my_calendar_510",
  view_org_calendar_511 = "view_org_calendar_511",
  view_school_calendar_512 = "view_school_calendar_512",
  view_review_calendar_events_513 = "view_review_calendar_events_513",
  create_event_520 = "create_event_520",
  create_my_schedule_events_521 = "create_my_schedule_events_521",
  create_my_schools_schedule_events_522 = "create_my_schools_schedule_events_522",
  create_review_calendar_events_523 = "create_review_calendar_events_523",
  create_live_calendar_events_524 = "create_live_calendar_events_524",
  create_class_calendar_events_525 = "create_class_calendar_events_525",
  create_study_calendar_events_526 = "create_study_calendar_events_526",
  create_home_fun_calendar_events_527 = "create_home_fun_calendar_events_527",
  edit_event_530 = "edit_event_530",
  override_live_classroom_recording_setting_531 = "override_live_classroom_recording_setting_531",
  delete_event_540 = "delete_event_540",
  schedule_settings_580 = "schedule_settings_580",
  schedule_quick_start_581 = "schedule_quick_start_581",
  schedule_search_582 = "schedule_search_582",
  reports_600 = "reports_600",
  org_reports_601 = "org_reports_601",
  school_reports_602 = "school_reports_602",
  teacher_reports_603 = "teacher_reports_603",
  class_reports_604 = "class_reports_604",
  student_reports_605 = "student_reports_605",
  view_reports_610 = "view_reports_610",
  view_my_school_reports_611 = "view_my_school_reports_611",
  view_my_organizations_reports_612 = "view_my_organizations_reports_612",
  view_all_organizations_reports_613 = "view_all_organizations_reports_613",
  view_my_reports_614 = "view_my_reports_614",
  report_student_achievement_615 = "report_student_achievement_615",
  report_learning_outcomes_in_categories_616 = "report_learning_outcomes_in_categories_616",
  report_organization_teaching_load_617 = "report_organization_teaching_load_617",
  report_school_teaching_load_618 = "report_school_teaching_load_618",
  report_my_teaching_load_619 = "report_my_teaching_load_619",
  teachers_classes_teaching_time_report_620 = "teachers_classes_teaching_time_report_620",
  class_load_time_report_621 = "class_load_time_report_621",
  time_assessing_load_report_622 = "time_assessing_load_report_622",
  a_teachers_detailed_time_load_report_623 = "a_teachers_detailed_time_load_report_623",
  a_teachers_schedule_load_report_624 = "a_teachers_schedule_load_report_624",
  a_teachers_detailed_schedule_load_report_625 = "a_teachers_detailed_schedule_load_report_625",
  organization_class_achievements_report_626 = "organization_class_achievements_report_626",
  school_class_achievements_report_627 = "school_class_achievements_report_627",
  my_class_achievements_report_628 = "my_class_achievements_report_628",
  my_student_achievements_report_629 = "my_student_achievements_report_629",
  report_organizations_skills_taught_640 = "report_organizations_skills_taught_640",
  report_schools_skills_taught_641 = "report_schools_skills_taught_641",
  report_my_skills_taught_642 = "report_my_skills_taught_642",
  skills_taught_by_all_teachers_in_this_org_report_643 = "skills_taught_by_all_teachers_in_this_org_report_643",
  skills_taught_by_all_teachers_in_my_school_report_644 = "skills_taught_by_all_teachers_in_my_school_report_644",
  a_teachers_skills_taught_report_645 = "a_teachers_skills_taught_report_645",
  report_organizations_class_achievements_646 = "report_organizations_class_achievements_646",
  report_schools_class_achievements_647 = "report_schools_class_achievements_647",
  report_my_class_achievments_648 = "report_my_class_achievments_648",
  report_learning_summary_student_649 = "report_learning_summary_student_649",
  report_learning_summary_teacher_650 = "report_learning_summary_teacher_650",
  report_learning_summary_school_651 = "report_learning_summary_school_651",
  report_learning_summary_org_652 = "report_learning_summary_org_652",
  learning_summary_report_653 = "learning_summary_report_653",
  report_organization_student_usage_654 = "report_organization_student_usage_654",
  report_school_student_usage_655 = "report_school_student_usage_655",
  report_teacher_student_usage_656 = "report_teacher_student_usage_656",
  student_usage_report_657 = "student_usage_report_657",
  report_student_progress_organization_658 = "report_student_progress_organization_658",
  report_student_progress_school_659 = "report_student_progress_school_659",
  report_student_progress_teacher_660 = "report_student_progress_teacher_660",
  report_student_progress_student_661 = "report_student_progress_student_661",
  student_progress_report_662 = "student_progress_report_662",
  view_teacher_feedback_670 = "view_teacher_feedback_670",
  share_report_630 = "share_report_630",
  download_report_631 = "download_report_631",
  report_settings_680 = "report_settings_680",
  organizational_profile_10100 = "organizational_profile_10100",
  view_all_organization_details_page_10101 = "view_all_organization_details_page_10101",
  view_this_organization_profile_10110 = "view_this_organization_profile_10110",
  view_my_organization_profile_10111 = "view_my_organization_profile_10111",
  view_organization_details_10112 = "view_organization_details_10112",
  create_own_organization_10220 = "create_own_organization_10220",
  reactivate_own_organization_10221 = "reactivate_own_organization_10221",
  edit_this_organization_10330 = "edit_this_organization_10330",
  edit_my_organization_10331 = "edit_my_organization_10331",
  edit_email_address_10332 = "edit_email_address_10332",
  edit_password_10333 = "edit_password_10333",
  delete_organization_10440 = "delete_organization_10440",
  change_owner_10880 = "change_owner_10880",
  join_organization_10881 = "join_organization_10881",
  leave_organization_10882 = "leave_organization_10882",
  academic_profile_20100 = "academic_profile_20100",
  define_school_program_page_20101 = "define_school_program_page_20101",
  define_age_ranges_page_20102 = "define_age_ranges_page_20102",
  define_grade_page_20103 = "define_grade_page_20103",
  define_class_page_20104 = "define_class_page_20104",
  define_program_page_20105 = "define_program_page_20105",
  define_subject_page_20106 = "define_subject_page_20106",
  view_school_20110 = "view_school_20110",
  view_my_school_20119 = "view_my_school_20119",
  view_program_20111 = "view_program_20111",
  view_age_range_20112 = "view_age_range_20112",
  view_grades_20113 = "view_grades_20113",
  view_classes_20114 = "view_classes_20114",
  view_school_classes_20117 = "view_school_classes_20117",
  view_my_classes_20118 = "view_my_classes_20118",
  view_subjects_20115 = "view_subjects_20115",
  view_class_roster_20116 = "view_class_roster_20116",
  create_school_20220 = "create_school_20220",
  create_program_20221 = "create_program_20221",
  create_age_range_20222 = "create_age_range_20222",
  create_grade_20223 = "create_grade_20223",
  create_class_20224 = "create_class_20224",
  create_school_classes_20228 = "create_school_classes_20228",
  add_students_to_class_20225 = "add_students_to_class_20225",
  add_teachers_to_class_20226 = "add_teachers_to_class_20226",
  create_subjects_20227 = "create_subjects_20227",
  edit_school_20330 = "edit_school_20330",
  edit_program_20331 = "edit_program_20331",
  edit_age_range_20332 = "edit_age_range_20332",
  edit_grade_20333 = "edit_grade_20333",
  edit_class_20334 = "edit_class_20334",
  edit_school_class_20339 = "edit_school_class_20339",
  move_students_to_another_class_20335 = "move_students_to_another_class_20335",
  edit_teacher_in_class_20336 = "edit_teacher_in_class_20336",
  edit_subjects_20337 = "edit_subjects_20337",
  edit_students_in_class_20338 = "edit_students_in_class_20338",
  delete_school_20440 = "delete_school_20440",
  delete_program_20441 = "delete_program_20441",
  delete_age_range_20442 = "delete_age_range_20442",
  delete_grade_20443 = "delete_grade_20443",
  delete_class_20444 = "delete_class_20444",
  delete_school_class_20448 = "delete_school_class_20448",
  delete_student_from_class_roster_20445 = "delete_student_from_class_roster_20445",
  delete_teacher_from_class_20446 = "delete_teacher_from_class_20446",
  delete_subjects_20447 = "delete_subjects_20447",
  upload_schools_20880 = "upload_schools_20880",
  download_schools_20881 = "download_schools_20881",
  upload_program_20882 = "upload_program_20882",
  download_program_20883 = "download_program_20883",
  upload_class_roster_with_teachers_20884 = "upload_class_roster_with_teachers_20884",
  download_class_roster_with_teachers_20885 = "download_class_roster_with_teachers_20885",
  upload_age_range_20886 = "upload_age_range_20886",
  download_age_range_20887 = "download_age_range_20887",
  upload_grades_20888 = "upload_grades_20888",
  download_grades_20889 = "download_grades_20889",
  upload_classes_20890 = "upload_classes_20890",
  download_classes_20891 = "download_classes_20891",
  upload_subject_20892 = "upload_subject_20892",
  download_subject_20893 = "download_subject_20893",
  roles_30100 = "roles_30100",
  role_levels_30101 = "role_levels_30101",
  roles_and_permissions_30102 = "roles_and_permissions_30102",
  view_roles_and_permissions_30110 = "view_roles_and_permissions_30110",
  view_role_priority_levels_30111 = "view_role_priority_levels_30111",
  view_role_permissions_30112 = "view_role_permissions_30112",
  create_groups_30220 = "create_groups_30220",
  create_role_priority_levels_30221 = "create_role_priority_levels_30221",
  create_role_with_permissions_30222 = "create_role_with_permissions_30222",
  edit_groups_30330 = "edit_groups_30330",
  edit_role_priority_levels_30331 = "edit_role_priority_levels_30331",
  edit_role_and_permissions_30332 = "edit_role_and_permissions_30332",
  delete_role_30440 = "delete_role_30440",
  users_40100 = "users_40100",
  view_user_page_40101 = "view_user_page_40101",
  view_users_40110 = "view_users_40110",
  view_my_school_users_40111 = "view_my_school_users_40111",
  view_my_class_users_40112 = "view_my_class_users_40112",
  view_my_users_40113 = "view_my_users_40113",
  create_users_40220 = "create_users_40220",
  create_my_school_users_40221 = "create_my_school_users_40221",
  create_my_class_users_40222 = "create_my_class_users_40222",
  create_my_users_40223 = "create_my_users_40223",
  edit_users_40330 = "edit_users_40330",
  edit_my_school_users_40331 = "edit_my_school_users_40331",
  edit_my_class_users_40332 = "edit_my_class_users_40332",
  edit_my_users_40333 = "edit_my_users_40333",
  delete_users_40440 = "delete_users_40440",
  delete_my_school_users_40441 = "delete_my_school_users_40441",
  delete_my_class_users_40442 = "delete_my_class_users_40442",
  delete_my_users_40443 = "delete_my_users_40443",
  upload_users_40880 = "upload_users_40880",
  download_users_40881 = "download_users_40881",
  send_invitation_40882 = "send_invitation_40882",
  deactivate_user_40883 = "deactivate_user_40883",
  billing_50100 = "billing_50100",
  view_payment_details_and_make_payment_50101 = "view_payment_details_and_make_payment_50101",
  view_billing_details_50110 = "view_billing_details_50110",
  purchase_more_minutes_50880 = "purchase_more_minutes_50880",
  purchase_more_storage_50881 = "purchase_more_storage_50881",
  purchase_next_plan_50882 = "purchase_next_plan_50882",
  download_billing_50883 = "download_billing_50883",
  support_60100 = "support_60100",
  online_support_60101 = "online_support_60101",
  featured_programs_70000 = "featured_programs_70000",
  view_any_featured_programs_70001 = "view_any_featured_programs_70001",
  view_bada_rhyme_71000 = "view_bada_rhyme_71000",
  view_bada_genius_71001 = "view_bada_genius_71001",
  view_bada_talk_71002 = "view_bada_talk_71002",
  view_bada_sound_71003 = "view_bada_sound_71003",
  view_bada_read_71004 = "view_bada_read_71004",
  view_bada_math_71005 = "view_bada_math_71005",
  view_bada_stem_71006 = "view_bada_stem_71006",
  view_badanamu_esl_71007 = "view_badanamu_esl_71007",
  undefined_71008 = "undefined_71008",
  undefined_71009 = "undefined_71009",
  create_bada_rhyme_75000 = "create_bada_rhyme_75000",
  create_bada_genius_75001 = "create_bada_genius_75001",
  create_bada_talk_75002 = "create_bada_talk_75002",
  create_bada_sound_75003 = "create_bada_sound_75003",
  create_bada_read_75004 = "create_bada_read_75004",
  create_bada_math_75005 = "create_bada_math_75005",
  create_bada_stem_75006 = "create_bada_stem_75006",
  create_badanamu_esl_75007 = "create_badanamu_esl_75007",
  undefined_75008 = "undefined_75008",
  undefined_75009 = "undefined_75009",
  edit_bada_rhyme_78000 = "edit_bada_rhyme_78000",
  edit_bada_genius_78001 = "edit_bada_genius_78001",
  edit_bada_talk_78002 = "edit_bada_talk_78002",
  edit_bada_sound_78003 = "edit_bada_sound_78003",
  edit_bada_read_78004 = "edit_bada_read_78004",
  edit_bada_math_78005 = "edit_bada_math_78005",
  edit_bada_stem_78006 = "edit_bada_stem_78006",
  edit_badanamu_esl_78007 = "edit_badanamu_esl_78007",
  copy_featured_content_into_library_78008 = "copy_featured_content_into_library_78008",
  download_featured_content_78009 = "download_featured_content_78009",
  publish_featured_content_for_all_hub_79000 = "publish_featured_content_for_all_hub_79000",
  publish_featured_content_for_specific_orgs_79001 = "publish_featured_content_for_specific_orgs_79001",
  publish_featured_content_for_all_orgs_79002 = "publish_featured_content_for_all_orgs_79002",
  free_programs_80000 = "free_programs_80000",
  view_free_programs_80001 = "view_free_programs_80001",
  view_bada_rhyme_81000 = "view_bada_rhyme_81000",
  view_bada_genius_81001 = "view_bada_genius_81001",
  view_bada_talk_81002 = "view_bada_talk_81002",
  view_bada_sound_81003 = "view_bada_sound_81003",
  view_bada_read_81004 = "view_bada_read_81004",
  view_bada_math_81005 = "view_bada_math_81005",
  view_bada_stem_81006 = "view_bada_stem_81006",
  view_badanamu_esl_81007 = "view_badanamu_esl_81007",
  use_free_as_recommended_content_for_study_81008 = "use_free_as_recommended_content_for_study_81008",
  undefinend_81009 = "undefinend_81009",
  create_bada_rhyme_85000 = "create_bada_rhyme_85000",
  create_bada_genius_85001 = "create_bada_genius_85001",
  create_bada_talk_85002 = "create_bada_talk_85002",
  create_bada_sound_85003 = "create_bada_sound_85003",
  create_bada_read_85004 = "create_bada_read_85004",
  create_bada_math_85005 = "create_bada_math_85005",
  create_bada_stem_85006 = "create_bada_stem_85006",
  undefined_85007 = "undefined_85007",
  undefined_85008 = "undefined_85008",
  undefined_85009 = "undefined_85009",
  edit_bada_rhyme_88000 = "edit_bada_rhyme_88000",
  edit_bada_genius_88001 = "edit_bada_genius_88001",
  edit_bada_talk_88002 = "edit_bada_talk_88002",
  edit_bada_sound_88003 = "edit_bada_sound_88003",
  edit_bada_read_88004 = "edit_bada_read_88004",
  edit_bada_math_88005 = "edit_bada_math_88005",
  edit_bada_stem_88006 = "edit_bada_stem_88006",
  edit_badanamu_esl_88007 = "edit_badanamu_esl_88007",
  copy_free_content_into_library_88008 = "copy_free_content_into_library_88008",
  download_free_content__88009 = "download_free_content__88009",
  publish_free_content_for_all_hub_89000 = "publish_free_content_for_all_hub_89000",
  publish_free_content_for_specific_orgs_89001 = "publish_free_content_for_specific_orgs_89001",
  publish_free_content_for_all_orgs_89002 = "publish_free_content_for_all_orgs_89002",
}

export default PermissionType;
